<template>
  <div>
    <cta-banner :title="$route.name"/>
    <section class="container mx-auto pb-28" v-if="trabajos.length > 0">
      <cta-description class="md:pt-28" v-if="header" :descripcion="header" :patrocinar="true" />
      <cta-table :memorias="trabajos" :isButton="false" :isAutores="true" />
    </section>
  </div>
</template>

<script>
import CtaBanner from '../../components/common/CtaBanner.vue';
import CtaTable from '../../components/CtaTable.vue';
import CtaDescription from '../../components/CtaDescription.vue';
import API from "@/api";
import {mapState} from "vuex";

export default {
  name: "Trabajos",
  components: {
    CtaBanner,
    CtaTable,
    CtaDescription
  },
  data() {
    return {
      trabajos: [],
      header: {}
    };
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  created() {
    this.getTrabajos();
    this.getInfo();
  },
  methods: {
    async getTrabajos() {
      const { data } = await API.getTrabajos({ evento_fk: this.idEventoActual });
      this.trabajos = data.results;
    },
    async getInfo() {
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.header = (items[i].codigo === 'AU01') ? items[i] : this.header;
      }
    }
  },
}
</script>

<style scoped>
  .title::before{
    content: '';
    position: absolute;
    width: 3rem;
    top: -15px;
    border-radius: 20px;
    border: 2px solid var(--theme-color);
  }
</style>
